"use client";

import React, {ReactNode, useEffect, useState} from "react";
import classes from "classnames";
import {RewrittenCmsLink} from "@app/poiscaille-types";

import {useViewport} from "@app/contexts/ViewportProvider";
import NavbarAccount from "./NavbarAccount";
import NavbarActionButton from "./NavbarActionButton";
import NavbarMenuMobile from "./NavbarMenuMobile";
import Section from "@ng-components/primitives/Section";
import Suite from "@ng-components/primitives/Suite";
import Container from "../../primitives/Container";

const NAVBAR_HEIGHT = 72;
const THRESHOLD = 160;

type NavbarInnerProps = {
    action?: RewrittenCmsLink;
    backgroundColor?: string;
    links?: (RewrittenCmsLink | undefined)[];
    NavbarMenuDesktop: ReactNode;
    NavbarLogo: ReactNode;
    reference: string;
    internalTitle: string;
};

export default function NavbarInner({
    action,
    backgroundColor = "yellow",
    links = [],
    NavbarMenuDesktop,
    NavbarLogo,
    reference,
    internalTitle,
}: NavbarInnerProps) {
    const textColor = backgroundColor === "blue" ? "white" : "black";

    const [scrollY, setScrollY] = useState(0);
    const [whereYouStoppedScrolling, setWhereYouStoppedScrolling] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [horizon, setHorizon] = useState(NAVBAR_HEIGHT);
    const [style, setStyle] = useState({transform: ""});
    const [shadowStyle, setShadowStyle] = useState({transform: "", opacity: 1});
    const [withTranslation, setWithTranslation] = useState(true);

    const upOrDown = (lastY: number, currentY: number) => {
        currentY >= lastY ? goingDown(currentY) : goingUp(currentY);
    };

    const goingDown = (currentY: number) => {
        setWhereYouStoppedScrolling(currentY);

        let nextHorizon = horizon;
        if (currentY > horizon) {
            nextHorizon = currentY;
            setHorizon(nextHorizon);
        }

        translateHeader(currentY, false, nextHorizon);
    };

    const goingUp = (currentY: number) => {
        let nextHorizon = horizon;
        if (currentY < whereYouStoppedScrolling - NAVBAR_HEIGHT) {
            nextHorizon = currentY + NAVBAR_HEIGHT;
            setHorizon(nextHorizon);
        }

        translateHeader(currentY, true, nextHorizon);
    };

    const constrainDelta = (delta: number) => {
        return Math.max(0, Math.min(delta, NAVBAR_HEIGHT));
    };

    const translateHeader = (currentY: number, upwards: boolean, horizon: number) => {
        let translateValue = 0;

        if (withTranslation === false) {
            translateValue = 0;
        } else if ((upwards && currentTranslate == 0) || currentY <= 0) {
            translateValue = 0;
        } else {
            const delta = constrainDelta(Math.abs(currentY - horizon));
            translateValue = delta - NAVBAR_HEIGHT;
        }

        const nextStyle = {transform: style.transform};
        if (translateValue != currentTranslate) {
            setCurrentTranslate(translateValue);
            nextStyle.transform = `translateY(${translateValue}px)`;
        }

        setStyle(nextStyle);

        let scrollFactor = 0;
        if (currentY > THRESHOLD * 2) {
            scrollFactor = 1;
        } else if (currentY > THRESHOLD) {
            scrollFactor = (currentY - THRESHOLD) / THRESHOLD;
        }

        const translateFactor = 1 + translateValue / NAVBAR_HEIGHT;
        setShadowStyle({opacity: scrollFactor, transform: `scaleY(${translateFactor})`});
    };

    const trackScrollY = () => {
        upOrDown(scrollY, window.pageYOffset);
        setScrollY(window.pageYOffset);
    };

    useEffect(() => {
        window.addEventListener("scroll", trackScrollY);
        return () => {
            window.removeEventListener("scroll", trackScrollY);
        };
    });

    const {setNavbarHeight} = useViewport();
    useEffect(() => {
        setNavbarHeight(NAVBAR_HEIGHT + currentTranslate);
    }, [currentTranslate]);

    return (
        <Section
            as="nav"
            paddingTop="none"
            paddingBottom="none"
            className={classes("ng-navbar", `ng-background-color-${backgroundColor}`)}
            aria-label="main navigation"
            style={style}
            withContainer={false}
        >
            <Container>
                <Suite className="ng-navbar-content" justify="space-between" verticalAlign="fill" wrap="never">
                    <Suite justify="left" spacing="small" verticalAlign="fill" wrap="never">
                        {NavbarLogo}
                        {NavbarMenuDesktop}
                    </Suite>
                    <Suite justify="right" spacing="small" verticalAlign="fill" wrap="never">
                        <NavbarAccount setNavbarTranslation={setWithTranslation} textColor={textColor} />
                        <div className="ng-navbar-action-button-wrapper">
                            <NavbarActionButton
                                action={action}
                                color={backgroundColor === "blue" ? "yellow" : "blue"}
                                reference={reference}
                                internalTitle={internalTitle}
                            />
                        </div>
                        <NavbarMenuMobile
                            action={action}
                            internalTitle={internalTitle}
                            links={links}
                            reference={reference}
                            setNavbarTranslation={setWithTranslation}
                            textColor={textColor}
                        />
                    </Suite>
                </Suite>
            </Container>
            <div className="ng-navbar-shadow" style={shadowStyle} />
        </Section>
    );
}
