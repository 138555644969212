"use client";
import React from "react";
import {usePathname} from "next/navigation";

import Link from "@ng-components/actions/Link";
import Paragraph from "@ng-components/primitives/Paragraph";
import Section from "@ng-components/primitives/Section";

type BannerData =
    | {
          backgroundColor: "grey" | "turquoise";
          text?: string;
          url?: string;
      }
    | undefined;

type BannerContentProps = {
    userBanner: BannerData;
    retailerBanner: BannerData;
    excludedPathnames?: string[];
};

export default function BannerContent({userBanner, retailerBanner, excludedPathnames = []}: BannerContentProps) {
    const pathname = usePathname();

    const banner = pathname.startsWith("/compte/points-relais") ? retailerBanner : userBanner;

    if (pathname.match(/\/compte\/abonnements\/.*\/casier/)) return null;
    if (excludedPathnames.includes(pathname)) return null;

    const {backgroundColor, text, url: bannerUrl} = banner || {};
    const url = bannerUrl === pathname ? undefined : bannerUrl;

    if (!text) return null;

    return (
        <Section backgroundColor={backgroundColor} paddingTop="none" paddingBottom="none">
            <Paragraph justify="center">
                {url ? (
                    <Link className="ng-banner" text={text} underline="hover" url={url} />
                ) : (
                    <strong className="ng-banner">{text}</strong>
                )}
            </Paragraph>
        </Section>
    );
}
