import React from "react";
import classes from "classnames";

interface SectionProps extends PrimitiveAsProps {
    backgroundLayer?: "none" | "snow";
    paddingTop?: "small" | "none";
    paddingBottom?: "small" | "none";
    paddingLeft?: "none" | "large";
    paddingRight?: "none" | "large";
    verticalAlign?: "center" | "top";
    verticalFill?: boolean;
    mobileFullWidth?: boolean;
    width?: "carousel";
    format?: "carousel";
}

export default function Container({
    children,
    mobileFullWidth = false,
    paddingLeft,
    paddingRight,
    verticalAlign,
    verticalFill = false,
    width,
}: SectionProps) {
    return (
        <div
            className={classes("ng-container", {
                "ng-vertical-fill": verticalFill,
                [`ng-padding-left-${paddingLeft}`]: paddingLeft,
                [`ng-padding-right-${paddingRight}`]: paddingRight,
                [`ng-vertical-align-${verticalAlign}`]: verticalAlign,
                [`ng-width-${width}`]: width,
                "ng-mobile-full-width": mobileFullWidth,
            })}
        >
            {children}
        </div>
    );
}
