"use client";
import React from "react";
import classes from "classnames";

type FooterLinkCookiesPreferencesProps = {
    textColor?: "black" | "white";
};

const LINK_TEXT = "Paramétrage des cookies";

export default function FooterLinkCookiesPreferences({textColor = "black"}: FooterLinkCookiesPreferencesProps) {
    return (
        <li>
            <a
                className={classes("ng-footer-link", {
                    [`ng-text-color-${textColor}`]: textColor,
                })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onClick={() => CookieScript.instance.show()}
            >
                {LINK_TEXT}
            </a>
        </li>
    );
}
