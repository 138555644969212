import React, {ReactNode} from "react";

import NavbarLink from "./NavbarLink";

interface NavbarAccountButtonProps {
    buttonText: ReactNode;
    textColor?: "black" | "white";
}

export default function NavbarAccountButton({buttonText, textColor = "black"}: NavbarAccountButtonProps) {
    return (
        <NavbarLink
            className="ng-navbar-account-button"
            data-cy="navbar-account-button"
            url="/compte"
            textColor={textColor}
            title="Mon compte"
        >
            {buttonText}
        </NavbarLink>
    );
}
