import React from "react";
import classes from "classnames";
import CSS from "csstype";
import {Color} from "@app/poiscaille-types";

import Container from "@ng-components/primitives/Container";
import Snow from "@ng-components/background-layers/Snow";

interface SectionProps extends PrimitiveAsProps {
    backgroundColor?: Omit<Color, "orange" | "green">;
    backgroundLayer?: "none" | "snow";
    paddingTop?: "small" | "none";
    paddingBottom?: "small" | "none";
    paddingLeft?: "none" | "large";
    paddingRight?: "none" | "large";
    verticalAlign?: "center" | "top";
    verticalFill?: boolean;
    mobileFullWidth?: boolean;
    format?: "carousel";
    withContainer?: boolean;
    style?: CSS.Properties;
}

export default function Section({
    "as": Tag = "div",
    backgroundColor = "white",
    backgroundLayer,
    children,
    className,
    "data-cy": dataCy,
    mobileFullWidth = false,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    verticalAlign,
    verticalFill = false,
    format,
    withContainer = true,
    style,
}: SectionProps) {
    return (
        <Tag
            className={classes(
                "ng-section",
                `ng-background-color-${backgroundColor}`,
                {
                    [`ng-padding-top-${paddingTop}`]: paddingTop,
                    [`ng-padding-bottom-${paddingBottom}`]: paddingBottom,
                    "ng-vertical-fill": verticalFill,
                },
                className,
            )}
            data-cy={dataCy}
            style={style}
        >
            {backgroundLayer === "snow" && <Snow />}
            {withContainer ? (
                <Container
                    format={format}
                    mobileFullWidth={mobileFullWidth}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    verticalAlign={verticalAlign}
                    verticalFill={verticalFill}
                >
                    {children}
                </Container>
            ) : (
                <>{children}</>
            )}
        </Tag>
    );
}
