import React from "react";
import classes from "classnames";

type NavbarBurgerProps = {
    isOpen?: boolean;
    color?: "black" | "white";
};

export default function NavbarBurger({isOpen = true, color = "black"}: NavbarBurgerProps) {
    return (
        <div
            className={classes("ng-navbar-burger", `ng-color-${color}`, {
                "is-open": isOpen,
            })}
        >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </div>
    );
}
