import React, {ReactNode} from "react";
import classes from "classnames";
import Link from "next/link";

interface NavbarLinkProps {
    children: ReactNode;
    className?: string;
    onClick?: () => any;
    target?: string;
    textColor?: "black" | "white";
    title?: string;
    url: string;
}

export default function NavbarLink({
    children,
    className,
    onClick,
    target,
    textColor = "black",
    title,
    url,
}: NavbarLinkProps) {
    return (
        <Link
            className={classes("ng-navbar-link", `ng-text-color-${textColor}`, `${className}`)}
            target={target}
            title={title}
            href={url}
            onClick={onClick}
        >
            {children}
        </Link>
    );
}
