import React from "react";

const SNOWFLAKES_QUANTITY = 50;

export default function Snow() {
    return (
        <div className="ng-snow">
            {[...Array(SNOWFLAKES_QUANTITY)].map((snowflake, index) => (
                <div className="ng-snowflake" key={`snowflake-${index}`} />
            ))}
        </div>
    );
}
