"use client";

import React, {Dispatch, SetStateAction, useEffect} from "react";
import Link from "next/link";
import classes from "classnames";
import {Popover} from "@headlessui/react";
import {RewrittenCmsLink} from "@app/poiscaille-types";

import NavbarActionButton from "./NavbarActionButton";
import NavbarBurger from "./NavbarBurger";

interface NavbarMenuMobileProps {
    action?: RewrittenCmsLink;
    internalTitle: string;
    links?: (RewrittenCmsLink | undefined)[];
    reference: string;
    setNavbarTranslation: Dispatch<SetStateAction<boolean>>;
    textColor?: "black" | "white";
}

export default function NavbarMenuMobile({
    action,
    internalTitle,
    links = [],
    reference,
    setNavbarTranslation,
    textColor = "black",
}: NavbarMenuMobileProps) {
    return (
        <Popover as="div" className="ng-navbar-menu-mobile" data-cy="navbar-menu-mobile">
            {({open, close}) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    setNavbarTranslation(!open);
                }, [open]);

                return (
                    <>
                        <Popover.Button
                            className={classes("ng-navbar-menu-mobile-toggle-button", `ng-text-color-${textColor}`, {
                                "ng-active": open,
                            })}
                            data-cy="navbar-menu-mobile-toggle-button"
                        >
                            <NavbarBurger isOpen={open} color={textColor} />
                        </Popover.Button>
                        <Popover.Panel
                            as="div"
                            className="ng-navbar-menu-mobile-panel"
                            data-cy="navbar-menu-mobile-panel"
                        >
                            <ul className="ng-navbar-menu-mobile-links" data-cy="navbar-menu-mobile-links">
                                {links.map((link, index) => {
                                    const {url, text} = link || {};
                                    return url && text ? (
                                        <li key={index}>
                                            <Link className="ng-navbar-menu-mobile-link" href={url} onClick={close}>
                                                {text}
                                            </Link>
                                        </li>
                                    ) : null;
                                })}
                                <li className="ng-navbar-menu-mobile-action-wrapper">
                                    <NavbarActionButton
                                        action={action}
                                        color="blue"
                                        reference={reference}
                                        internalTitle={internalTitle}
                                        onClick={close}
                                    />
                                </li>
                            </ul>
                        </Popover.Panel>
                    </>
                );
            }}
        </Popover>
    );
}
