import React from "react";
import NextLink from "next/link";
import classes from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

type LinkLabelProps = {
    icon?: IconDefinition;
    text: string;
};

function LinkLabel({icon, text}: LinkLabelProps) {
    return (
        <>
            {icon && (
                <>
                    <FontAwesomeIcon icon={icon} fixedWidth />
                    &nbsp;
                </>
            )}
            {text}
        </>
    );
}

type LinkProps = {
    "onClick"?: () => void;
    "className"?: string;
    "color"?: "blue" | "white";
    "data-cy"?: string;
    "icon"?: IconDefinition;
    "target"?: string;
    "text": string;
    "underline"?: "hover";
    "url"?: string;
    "wrap"?: "never";
};

export default function Link({
    onClick,
    className,
    color = "blue",
    "data-cy": dataCy,
    icon,
    target = "_self",
    text,
    underline,
    url,
    wrap,
}: LinkProps) {
    if (url) {
        return (
            <NextLink
                className={classes(
                    "ng-link",
                    `ng-color-${color}`,
                    {[`ng-underline-${underline}`]: underline, [`ng-wrap-${wrap}`]: wrap},
                    className,
                )}
                data-cy={dataCy}
                target={target}
                href={url}
            >
                <LinkLabel icon={icon} text={text} />
            </NextLink>
        );
    }

    return (
        <button className={classes("ng-link", {[`ng-wrap-${wrap}`]: wrap})} data-cy={dataCy} onClick={onClick}>
            <LinkLabel icon={icon} text={text} />
        </button>
    );
}
