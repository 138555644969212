import React from "react";
import {RewrittenCmsLink} from "@app/poiscaille-types";
import {TrackingData} from "@shared/types/editorial-types";

import Button from "@ng-components/actions/Button";

type NavbarActionButtonProps = {
    action?: RewrittenCmsLink;
    color?: "blue" | "yellow";
    onClick?: () => void;
    reference: string;
    internalTitle: string;
};

export default function NavbarActionButton({
    action,
    color = "yellow",
    onClick,
    reference,
    internalTitle,
}: NavbarActionButtonProps) {
    const {text, url} = action || {};
    if (!text || !url) return null;

    const trackingData: TrackingData = {
        container: {type: "navbar", reference, internalTitle, title: null},
        trackClick: true,
        trackVisibility: false,
    };

    return <Button color={color} padding="small" onClick={onClick} text={text} trackingData={trackingData} url={url} />;
}
